
import Logo from '../../Assets/HeadLogo.JPG'
import Data from './Data'
import {IoIosColorPalette} from 'react-icons/io'
import {useModalContext} from '../../context/modal-context'
import './Navbar.css'

const Navbar = () => {
  const {showModalHandler} = useModalContext();
  return (
    <nav>
        <div className="container nav__container">
       <a href="index.html"  className='nav__logo'>
          <img src={Logo} alt="Logo" />
       </a>
       <ul className='nav__menu'>
        {
          Data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
        }
       </ul>
       <button id='theme__icon' onClick={showModalHandler}><IoIosColorPalette/></button>
    </div>
    </nav>
  )
}

export default Navbar
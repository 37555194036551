import {PiFigmaLogoDuotone} from "react-icons/pi";
import { SiBlender } from "react-icons/si"; 
import { FaUnity } from "react-icons/fa";
import { TbAugmentedReality } from "react-icons/tb";
import { SiUnrealengine } from "react-icons/si";


const data = [
    {id: 1, icon: <SiUnrealengine/>, title: 'UNREAL ENGINE', desc: "Game Development using Unreal MetaHuman, Blueprint System, Locomotion System, and Animation Blueprint."},
    {id: 2, icon: <FaUnity/>, title: 'UNITY', desc: "Experince in working with C# (C-Sharp) scripting with Raycasting Mechanisms, TPP and FPP Controller, Car Controller and Terrain (Environment)."},
    {id: 3, icon: <TbAugmentedReality/>, title: 'AR/VR DEVELOPER', desc: "Augmented Reality Learning Application and Game Development with the help of Unity and Vuforia Engine."},
    {id: 4, icon: <PiFigmaLogoDuotone/>, title: 'FIGMA', desc: "Wireframes (Low-Fidelity, High-Fidelity), Mockups, Flowcharts and Prototypes."},
    {id: 5, icon: <SiBlender/>, title: 'BLENDER', desc: "Model Development, Geometry Nodes, Environment Setup, Character Animation." }
]

export default data
import React from 'react'
import Resume from '../../Assets/Resume_Nipun Bansal_091224.pdf'
import AboutImage from '../../Assets/SetupImage.JPG'
 /*Change logo.jpg according the image that will be added, add it to the Assests folder*/
import {HiDownload} from 'react-icons/hi'
import Card from '../../components/Card'
import data from './data'
import './About.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
          <div className="about__left">
            <div className="about__portrait">
              <img src={AboutImage} alt="About Image" />
            </div>
          </div>
          <div className="about__right">
            <h2>About Me</h2>
            <div className="about__cards">
              {
                  data.map(item => (
                    <Card key={item.id} className="about__card">
                      <span className='about__card-icons'>{item.icon}</span>
                      <h5>{item.title}</h5>
                      <small>{item.desc}</small>
                      </Card>
                  ))
              }
            </div>
            <p>
              Hi - I'm Nipun Bansal, currently pursuing Masters in Fine Arts in Game Development from University of Texas at Dallas, United States.
              An enthusiatic and passionate Game Developer and Designer. Aiming to learn new techniques in AR and VR.   
            </p>
            <a href={Resume} download className='btn primary'> Download Resume
            <HiDownload/></a>
          </div>
      </div>
    </section>
  )
}

export default About

const Data = [
    {id: 1, link: '#' , title: 'HOME'},
    {id: 2, link: '#about' , title: 'ABOUT'},
    {id: 3, link: '#services' , title: 'SKILLS'},
    {id: 4, link: '#portfolio' , title: 'PROJECTS'},
    {id: 5, link: '#contact' , title: 'CONTACT'},

]

export default Data;
import {AiFillHome} from 'react-icons/ai'
import {BsPersonFill} from 'react-icons/bs'
import {MdHomeRepairService} from 'react-icons/md'
import {AiFillAppstore} from 'react-icons/ai'
import {AiFillMessage} from 'react-icons/ai'

const data = [
    {id: 1, link: '#', icon: <AiFillHome/>},
    {id: 1, link: '#about', icon: <BsPersonFill/>},
    {id: 1, link: '#services', icon: <MdHomeRepairService/>},
    {id: 1, link: '#portfolio', icon: <AiFillAppstore/>},
    {id: 1, link: '#contact', icon: <AiFillMessage/>}

]

export default data
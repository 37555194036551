import React from 'react'
import Navbar from './Sections/NavBar/Navbar';
import Header from './Sections/Header/Header';
import About from './Sections/About/About';
import Services from './Sections/Services/Services';
import Portfolio from './Sections/Portfolio/Portfolio';
import Contact from './Sections/Contact/Contact';
import Footer from './Sections/Footer/Footer';
import Themes from './Theme/Themes';
import { useThemeContext } from './context/theme-context';
import FloatingNav from './Sections/Floating-Nav/FloatingNav';
import { useRef, useState, useEffect } from 'react';

const App1 = () => {
 
  const {themeState} = useThemeContext();
  const mainRef = useRef();
  const [showFloatingNav, setShowFloatingNav] = useState(true);
  const [siteYPosition, setSiteYPosition] = useState(0);

  const showFloatingNavHandler = () => {
    setShowFloatingNav(true);
  }

  const hideFloatingNavHandler = () => {
    setShowFloatingNav(false);
  }

  const floatingNavToggleHandler = () => {
    if(siteYPosition < (mainRef.current?.getBoundingClientRect().y -20) || 
    siteYPosition > (mainRef.current?.getBoundingClientRect().y + 20))
    {
      showFloatingNavHandler();
    }
    else{
      hideFloatingNavHandler();
    }

    setSiteYPosition(mainRef?.current?.getBoundingClientRect().y);
  }

  useEffect(() => {
    const checkYPosition = setInterval(floatingNavToggleHandler, 2000);

    return() => clearInterval(checkYPosition);
  },  [siteYPosition])


  return (
    <main className={`${themeState.primary} ${themeState.background}`} ref={mainRef}>
        <Navbar/>
        <Header/>
        <About/>
        <Services/>
        <Portfolio/>
        <Contact/>
        <Footer/>
        <Themes/>
        {showFloatingNav && <FloatingNav/>}
    </main>
  )
}

export default App1
import {links, socials} from './data'
import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="conatainer footer__container">
        <ul className='nav__menu'>
          {
            links.map(fLink => <li key={fLink.id}><a href={fLink.link}>{fLink.title}</a></li>)
          }
        </ul>      
          <div className="footer__socials">
            {
              socials.map(social => <a key={social.id} href={social.link} target="_blank" 
              rel="noopener noreferrer">{social.icon}</a>)
            }
         </div>
       </div>
    </footer>
  )
}

export default Footer
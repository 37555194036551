import Image1 from '../../Assets/ActionAdvMain.jpg'
import Image2 from '../../Assets/InventorySystemMain.jpg'
import Image3 from '../../Assets/SpaceShooterMain.jpg'
import Image4 from '../../Assets/SchoolMPMain.jpg'
import Image5 from '../../Assets/ARMain.jpg'


const data = [
    {
        id: 1,
        category: "Unreal Engine",
        image: Image1,
        title: "Action-Adventure Game",
        desc: " It uses Unreal Engine 5.1, highlighting the Puzzle system using the Unreal Blueprint editor, Parkour Mechanics like Climbing, Vaulting, Mantling, and Animation with the Unreal Blueprint Animation",
        document: "https://drive.google.com/drive/folders/1VCgf1TRIRxL5tvtHVDU5Rtc6fptuMwny?usp=sharing",
        github: 'https://github.com/Nipun-15'

    },
    {
        id: 2,
        category: "Unity Engine",
        image: Image2,
        title: "Horror Survival Game",
        desc: " “Survival Game” using Unity - Post-Processing Volume, Inventory Menu and Weapon System (UI/UX designing), Lighting and usage of Baked Assets.",
        document: "https://drive.google.com/drive/folders/1VCgf1TRIRxL5tvtHVDU5Rtc6fptuMwny?usp=sharing",
        github: 'https://github.com/Nipun-15'

    },

    {
        id: 3,
        category: "Python Games",
        image: Image3,
        title: "2D-Space Shooter Game",
        desc: " “2-D Spaceship Game” with Python using the Pygame Libraries.",
        document: "https://drive.google.com/drive/folders/1VCgf1TRIRxL5tvtHVDU5Rtc6fptuMwny?usp=sharing",
        github: 'https://github.com/Nipun-15'

    },

    {
        id: 4,
        category: "JAVA",
        image: Image4,
        title: "School Management System",
        desc: " “School Management System” using HTML and Java for the front-end and PHP and MySQL implemented for the backend use",
        document: "https://drive.google.com/drive/folders/1VCgf1TRIRxL5tvtHVDU5Rtc6fptuMwny?usp=sharing",
        github: 'https://github.com/Nipun-15'

    },

    
    {
        id: 5,
        category: "Augmented Reality",
        image: Image5,
        title: "Animation Played using Virtual Button",
        desc: " To Display the Animation played by the character using the Canvas UI or Virtual Buttons. (Make use of the Vuforia SDK and Unity Augmented Reality AR)",
        document: "https://drive.google.com/drive/folders/1VCgf1TRIRxL5tvtHVDU5Rtc6fptuMwny?usp=sharing",
        github: 'https://github.com/Nipun-15'

    },




]
export default data
import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiOutlineGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#' , title: 'HOME'},
    {id: 2, link: '#about', title: 'ABOUT'},
    {id: 3, link: '#services', title: 'SKILLS'},
    {id: 4, link: '#portfolio', title: 'PROJECTS'},
    {id: 5, link: '#contact', title: 'CONTACTS'}
]

export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/nipun-bansal-7340b9231/', icon: <AiOutlineLinkedin/> },
    {id: 2, link: 'https://github.com/Nipun-15', icon: <AiOutlineGithub/> }
]
import contacts from './data'
import './Contact.css'

const Contact = () => {
  return (
    <section id="contact">
      <h2>Contacts</h2>
      <p> 
        Queries or Information, send me a via any of the links below!
      </p>
      <div className="container contact__container">
        {
          contacts.map(contact => <a key={contact.id} href={contact.link} target="_blank" rel="noopener
          noreferrer">{contact.icon}</a>)
        }
      </div>
      </section>
  )
}

export default Contact
import { SiEpicgames } from "react-icons/si";
import { MdOutlineSportsMartialArts } from "react-icons/md";
import { FaPlaystation } from "react-icons/fa6";

const data = [
    {id: 1, icon: <SiEpicgames/>, title: 'Major Game', desc: 'Developing an Action-Adventure Game using Unreal Engine 5'},
    {id: 2, icon: <MdOutlineSportsMartialArts/>, title: 'Martial Arts', desc: 'Black Belt in Choi-Kwang-Do Martial Arts and National Bronze Medalist'},
    {id: 3, icon: <FaPlaystation/>, title: 'Game-Genres', desc: 'Plays different Game Genre to understand Game Design and Development'}
]

export default data;